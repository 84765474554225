import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Search from '../components/common/Search';
// import Pricing from '../components/common/PricingTable';
import CategorySlider from '../components/homepage/CategorySlider';
import PopularCities from '../components/homepage/PopularCities';
import FeaturedBusiness from '../components/homepage/FeaturedBusiness';

const Home = () => {
// const Home = ({ isAuthenticated, messages }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch('/api/categories/');
      const data = await response.json();
      setCategories(data);
    };

    fetchCategories();
  }, []);

  return (
    <div data-new-title="Home Page | Desi Firms">
      <section id="banner" className="parallex-bg section-padding">
        <div className="container">
          <div className="intro_text white-text div_zindex">
            <img src={`${process.env.PUBLIC_URL}/static/images/df_logo_big_white.svg`} alt="title image desi firms" />
            <h3 style={{ fontFamily: 'Ethnocentric' }}>Find everything desi - businesses, events, deals & more!</h3>
            <div className="image_boxes my-3 py-3">
              <div className="image_box">
                <Link to="/all-listings">
                  <img src={`${process.env.PUBLIC_URL}/static/images/banner/biz.png`} alt="Business Listing Banner" />
                  <button className="btn mt-2" type="button">Go To</button>
                </Link>
              </div>
              <div className="image_box">
                <Link to="/deals-list">
                  <img src={`${process.env.PUBLIC_URL}/static/images/banner/deals.png`} alt="Deals Banner" />
                  <button className="btn mt-2" type="button">Go To</button>
                </Link>
              </div>
              <div className="image_box">
                <Link to="/events-list">
                  <img src={`${process.env.PUBLIC_URL}/static/images/banner/events.png`} alt="Events Banner" />
                  <button className="btn mt-2" type="button">Go To</button>
                </Link>
              </div>
            </div>
            <Search categories={categories} redirectOnSearch={true} />
          </div>
        </div>
      </section>

      <CategorySlider />
      <div id="featured_business_container">
        <FeaturedBusiness />
      </div>
      <PopularCities />

    

      {/* <Pricing isAuthenticated={isAuthenticated} messages={messages} /> */}
    </div>
  );
};

export default Home;
