import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';

const Filters = ({ onFilterChange, onClearFilters, selectedFilters }) => {
  const [categorySearch, setCategorySearch] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllCities, setShowAllCities] = useState(false);

  const { categories, cities } = useUser();

  const filteredCategories = categories.filter(category => 
    category.name && category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );

  const filteredCities = cities.filter(city => 
    city.name && city.name.toLowerCase().includes(citySearch.toLowerCase())
  );

  const visibleCategories = showAllCategories ? filteredCategories : filteredCategories.slice(0, 5);
  const visibleCities = showAllCities ? filteredCities : filteredCities.slice(0, 5);

  return (
    <div className="filter-container">
      <h5 className="filter-header">Filters</h5>
      
      <div className="filter-section">
        <h6>Categories</h6>
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search categories"
          value={categorySearch}
          onChange={(e) => setCategorySearch(e.target.value)}
        />
        <div className="scrollable-container" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {visibleCategories.map(category => (
            <div className="form-check" key={category.id}>
              <input
                className="form-check-input"
                type="checkbox"
                id={`category-${category.id}`}
                value={`category:${category.name}`}
                onChange={onFilterChange}
                checked={selectedFilters.category.includes(category.name)}
              />
              <label className="form-check-label" htmlFor={`category-${category.id}`}>
                {category.name}
              </label>
            </div>
          ))}
        </div>
        {filteredCategories.length > 5 && (
          <button 
            className="btn btn-block btn-sm mt-2"
            onClick={() => setShowAllCategories(!showAllCategories)}
          >
            {showAllCategories ? 'Show Less ▲' : 'Show More ▼'}
          </button>
        )}
      </div>

      <div className="filter-section">
        <h6>Cities</h6>
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search cities"
          value={citySearch}
          onChange={(e) => setCitySearch(e.target.value)}
        />
        <div className="scrollable-container" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {visibleCities.map((city, index) => (
            <div className="form-check" key={city.id}>
              <input
                className="form-check-input"
                type="checkbox"
                id={`city-${city.id}`}
                value={`city:${city.name}`}
                onChange={onFilterChange}
                checked={selectedFilters.city.includes(city.name)}
              />
              <label className="form-check-label" htmlFor={`city-${city.id}`}>
                {city.name}
              </label>
            </div>
          ))}
        </div>
        {filteredCities.length > 5 && (
          <button 
            className="btn btn-block btn-sm mt-2"
            onClick={() => setShowAllCities(!showAllCities)}
          >
            {showAllCities ? 'Show Less ▲' : 'Show More ▼'}
          </button>
        )}
      </div>

      <div className="filter-section">
        <h6>Rating</h6>
        {[5, 4, 3, 2, 1].map(rating => (
          <div className="form-check" key={rating}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`rating-${rating}`}
              value={`rating:${rating}`}
              onChange={onFilterChange}
              checked={selectedFilters.rating.includes(`${rating}`)}
            />
            <label className="form-check-label" htmlFor={`rating-${rating}`}>
              {rating} Stars & Up
            </label>
          </div>
        ))}
      </div>

      <button className="btn btn-primary w-100 mt-3" onClick={onClearFilters}>
        Clear All Filters
      </button>
    </div>
  );
};

export default Filters;

