import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import Map from '../components/common/Map';
import ContentStats from '../components/ratings/ContentStats';
import ReviewForm from '../components/ratings/ReviewForm';
import ReviewStats from '../components/ratings/ReviewStats';
import ReviewList from '../components/ratings/ReviewList';
import SharePopup from '../components/common/SharePopup';

const EventDetails = () => {
    const { slug } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [showSharePopup, setShowSharePopup] = useState(false);

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await fetch(`/api/event/${slug}/`);
                const data = await response.json();
                // console.log('Fetched event details:', data);

                const geometry = data.geometry;
                const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
                const match = geometry.match(pointRegex);

                if (match) {
                    setCoordinates({
                        lat: parseFloat(match[2]),
                        lng: parseFloat(match[1]),
                    });
                }

                setEvent({ ...data.properties, id: data.id });
            } catch (error) {
                // console.error('Error fetching event details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEventDetails();
    }, [slug]);

    const fetchReviews = useCallback(async () => {
        if (event && event.id) {
            try {
                await fetch(`/api/reviews-list/event/${event.id}/`);
            } catch (error) {
                // console.error('Error fetching reviews:', error);
            }
        }
    }, [event]);

    useEffect(() => {
        if (event) {
            fetchReviews();
        }
    }, [event, fetchReviews]);

    if (loading) {
        return <Spinner />;
    }

    if (!event) {
        return <div>Event not found</div>;
    }

    const shareableLink = `${window.location.origin}/event/${slug}`;

    return (
        <div>
            <section id="inner_banner_new" className="intro-bg parallex-bg section-padding">
                <div className="container">
                    <div className="row">
                        {/* You can add a title or other content here if needed */}
                    </div>
                </div>
                <div className="dark-overlay"></div>
            </section>
           
            <div className="container mt-2">          
                <div className="row">
                <div className="bg-white p-2 mt-5">      
                            <ContentStats contentType="event" contentId={event.id} />
                        </div>  
                    <div className="col-lg-8 col-md-12">
                        
                        <div className="event_detail">
                            <div className="event_img">
                                <img src={event.image || "assets/images/800x530.jpg"} alt={event.title} />
                            </div>
                            
                            <h1 className="event_detail_title">{event.title}</h1>
                        
                        </div>

                        {/* <div className="layout-three-address">
                            <div className="view_map_style3">
                                <a href="#viewmap" className="js-target-scroll"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
                            </div>
                            <p className="style_3_address">{event.address}</p>
                            <span className="post-date">
                        <p className="style_3_address">Start Date: {new Date(event.start_date).toLocaleDateString()}</p>
                        </span>
                        </div>
                        <p className="style_3_address">City:{event.city_name || 'City not available'}</p> */}
                        <div className="layout-three-address d-flex align-items-start mb-3">
                            <div className="view_map_style3">
                                <a href="#viewmap" className="js-target-scroll">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div>
                                <p className="style_3_address">
                                    {event.address}
                                </p>
                                <p className="style_3_address text-muted mb-1">
                                    <i className="fa fa-calendar-alt"></i> Start Date: {new Date(event.start_date).toLocaleDateString()}
                                </p>
                                <p className="style_3_address text-muted">
                                    <i className="fa fa-city"></i> City: {event.city_name || 'City not available'}
                                </p>
                                <ReviewStats contentType="event" contentId={event.id} />
                            </div>
                           
                        </div>

                       

                        <div className="detail-wp">
                            <div className="detail-heading">
                                <h3>Event Schedule</h3>
                            </div>
                            <div className="date-item">
                                <div className="event_start_m">
                                    <div className="date-item_date">Event Start Date</div>
                                    <div className="date-item_hours">{new Date(event.start_date).toLocaleDateString()}</div>
                                </div>
                                <div className="event_start_t">
                                    <div className="date-item_date">Opening Time</div>
                                    <div className="date-item_hours">{event.opening_time}</div>
                                </div>
                            </div>
                            <div className="date-item">
                                <div className="event_start_m">
                                    <div className="date-item_date">Event End Date</div>
                                    <div className="date-item_hours">{new Date(event.end_date).toLocaleDateString()}</div>
                                </div>
                                <div className="event_start_t">
                                    <div className="date-item_date">Closing Time</div>
                                    <div className="date-item_hours">{event.closing_time}</div>
                                </div>
                            </div>
                        </div>

                        <div className="detail-wp">
                            <div className="detail-heading">
                                <h3>Event Venue</h3>
                            </div>
                            <span className="map_icon"><i className="fa fa-map-marker"></i></span>
                            {event.address}
                        </div>

                        {/* <div className="detail-wp map-container" id="viewmap">
                            <div className="detail-heading">
                                <h3>Map</h3>
                            </div>
                            {coordinates.lat && coordinates.lng && (
                                <Map latitude={coordinates.lat} longitude={coordinates.lng} />
                            )}
                        </div> */}

                        <div className="detail-wp">
                            <div className="detail-heading">
                                <h3>About Event</h3>
                            </div>
                            <p>{event.description}</p>
                        </div>

                        <div className="detail-wp" id="review">
                            <div className="detail-heading">
                                <h3>Write Comment</h3>
                            </div>
                            <ReviewForm contentType="event" contentId={event.id} onReviewSubmitted={fetchReviews} />
                        </div>

                        <div className="detail-wp" id="reviews">
                            <ReviewList contentType="event" contentId={event.id} />
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="event_detail">
                            <div className="listing-sidebar">
                                <div className="sidebar_wrap listing_action_btn">
                                    <ul>
                                        <li><a onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i>Share This</a></li>
                                        <li><a href="#review" className="js-target-scroll"><i className="fa fa-comment"></i>Write Comment</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="listing-sidebar listing_contact_info">
                                <div className="sidebar-heading">
                                    <h5>Contact Info</h5>
                                </div>
                                <ul>
                                    {event.address && (
                                        <li><i className="fa fa-map-marker"></i>{event.address}</li>
                                    )}
                                    {event.city_name && (
                                        <li><i className="fa fa-globe"></i>{event.city_name}</li>
                                    )}
                                    {event.phone && (
                                        <li><i className="fa fa-phone"></i>{event.phone}</li>
                                    )}
                                    {event.email && (
                                        <li><i className="fa fa-envelope"></i>{event.email}</li>
                                    )}
                                    {event.website_url && (
                                        <li><i className="fa fa-globe"></i>{event.website_url}</li>
                                    )}
                                    {event.timezone && (
                                        <li><i className="fa fa-clock"></i>{event.timezone}</li>
                                    )}
                                </ul>
                            </div>
                            {coordinates.lat && coordinates.lng && (
                            <div className="bg-white border p-3 mb-3">
                                <h5 className="font-weight-bold">Location</h5>
                                <Map latitude={coordinates.lat} longitude={coordinates.lng} />
                            </div>
                        )}
                          
                           
                        </div>
                    </div>
                </div>
            </div>

            {showSharePopup && (
                <SharePopup link={shareableLink} onClose={() => setShowSharePopup(false)} />
            )}
        </div>
    );
};

export default EventDetails;

// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams } from 'react-router-dom';
// import Spinner from '../components/common/Spinner';
// import Map from '../components/common/Map';
// import ContentStats from '../components/ratings/ContentStats';
// import ReviewForm from '../components/ratings/ReviewForm';
// import ReviewStats from '../components/ratings/ReviewStats';
// import ReviewList from '../components/ratings/ReviewList';
// import SharePopup from '../components/common/SharePopup';

// const EventDetails = () => {
//     const { slug } = useParams();
//     const [event, setEvent] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
//     const [showSharePopup, setShowSharePopup] = useState(false);
//     const [contentStats, setContentStats] = useState(null); // For likes, bookmarks, views

//     useEffect(() => {
//         const fetchEventDetails = async () => {
//             try {
//                 const response = await fetch(`/api/event/${slug}/`);
//                 const data = await response.json();
//                 console.log('Fetched event details:', data);

//                 const geometry = data.geometry;
//                 const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
//                 const match = geometry.match(pointRegex);

//                 if (match) {
//                     setCoordinates({
//                         lat: parseFloat(match[2]),
//                         lng: parseFloat(match[1]),
//                     });
//                 }

//                 setEvent(data.properties);

//                 // Fetch content stats for likes, bookmarks, and views
//                 const statsResponse = await fetch(`/api/content-stats/event/${data.properties.id}/`);
//                 const statsData = await statsResponse.json();
//                 setContentStats(statsData);

//             } catch (error) {
//                 console.error('Error fetching event details:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchEventDetails();
//     }, [slug]);

//     const fetchReviews = useCallback(async () => {
//         if (event) {
//             try {
//                 await fetch(`/api/reviews-list/event/${event.id}/`);
//             } catch (error) {
//                 console.error('Error fetching reviews:', error);
//             }
//         }
//     }, [event]);

//     useEffect(() => {
//         if (event) {
//             fetchReviews();
//         }
//     }, [event, fetchReviews]);

//     if (loading) {
//         return <Spinner />;
//     }

//     if (!event) {
//         return <div>Event not found</div>;
//     }

//     const shareableLink = `${window.location.origin}/event/${slug}`;

//     return (
//         <div>
//             <section id="inner_banner_new" className="intro-bg parallex-bg section-padding">
//                 <div className="container">
//                     <div className="row">
//                         {/* You can add a title or other content here if needed */}
//                     </div>
//                 </div>
//                 <div className="dark-overlay"></div>
//             </section>

//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-8 col-md-12">
//                         <div className="event_detail">
//                             <div className="event_img">
//                                 <img src={event.image || "assets/images/800x530.jpg"} alt={event.title} />
//                             </div>
//                             <h1 className="event_detail_title">{event.title}</h1>
//                         </div>

//                         <div className="detail-wp">
//                             <div className="detail-heading">
//                                 <h3>Event Schedule</h3>
//                             </div>
//                             <div className="date-item">
//                                 <div className="event_start_m">
//                                     <div className="date-item_date">Event Start Date</div>
//                                     <div className="date-item_hours">{new Date(event.start_date).toLocaleDateString()}</div>
//                                 </div>
//                                 <div className="event_start_t">
//                                     <div className="date-item_date">Opening Time</div>
//                                     <div className="date-item_hours">{event.opening_time}</div>
//                                 </div>
//                             </div>
//                             <div className="date-item">
//                                 <div className="event_start_m">
//                                     <div className="date-item_date">Event End Date</div>
//                                     <div className="date-item_hours">{new Date(event.end_date).toLocaleDateString()}</div>
//                                 </div>
//                                 <div className="event_start_t">
//                                     <div className="date-item_date">Closing Time</div>
//                                     <div className="date-item_hours">{event.closing_time}</div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="detail-wp">
//                             <div className="detail-heading">
//                                 <h3>Event Venue</h3>
//                             </div>
//                             <span className="map_icon"><i className="fa fa-map-marker"></i></span>
//                             {event.address}
//                         </div>

//                         {/* Map Section */}
//                         <div className="detail-wp map-container" id="viewmap">
//                             <div className="detail-heading">
//                                 <h3>Map</h3>
//                             </div>
//                             {coordinates.lat && coordinates.lng && (
//                                 <Map
//                                     latitude={coordinates.lat}
//                                     longitude={coordinates.lng}
//                                 />
//                             )}
//                         </div>

//                         {/* Content Stats (likes, bookmarks, views) */}
//                         <div className="listing-meta">
//                             <div className="row">
//                                 <ContentStats stats={contentStats} />
//                             </div>
//                         </div>

//                         {/* Review Stats */}
//                         <ReviewStats contentType="event" contentId={event.id} />

//                         <div className="detail-wp">
//                             <div className="detail-heading">
//                                 <h3>About Event</h3>
//                             </div>
//                             <p>{event.description}</p>
//                         </div>

//                         <div className="detail-wp" id="review">
//                             <div className="detail-heading">
//                                 <h3>Write Comment</h3>
//                             </div>
//                             <ReviewForm
//                                 contentType="event"
//                                 contentId={event.id}
//                                 onReviewSubmitted={fetchReviews}
//                             />
//                         </div>

//                         <div className="detail-wp" id="reviews">
//                             <ReviewList contentType="event" contentId={event.id} />
//                         </div>
//                     </div>

//                     <div className="col-lg-4 col-md-12">
//                         <div className="event_detail">
//                             <div className="listing-sidebar">
//                                 <div className="sidebar_wrap listing_action_btn">
//                                     <ul>
//                                         <li><a href="#" className="batn" title="interested"><i className="fa fa-star-o"></i>INTERESTED</a></li>
//                                         <li><a onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i>Share This</a></li>
//                                         <li><a href="#review" className="js-target-scroll"><i className="fa fa-comment"></i>Write Comment</a></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                             <div className="listing-sidebar listing_contact_info">
//                                 <div className="sidebar-heading">
//                                     <h5>Contact Info</h5>
//                                 </div>
//                                 <ul>
//                                     {event.address && (
//                                         <li><i className="fa fa-map-marker"></i>{event.address}</li>
//                                     )}
//                                     {event.city_name && (
//                                         <li><i className="fa fa-globe"></i>{event.city_name}</li>
//                                     )}
//                                     {event.phone && (
//                                         <li><i className="fa fa-phone"></i>{event.phone}</li>
//                                     )}
//                                     {event.email && (
//                                         <li><i className="fa fa-envelope"></i>{event.email}</li>
//                                     )}
//                                     {event.website_url && (
//                                         <li><i className="fa fa-globe"></i>{event.website_url}</li>
//                                     )}
//                                     {event.timezone && (
//                                         <li><i className="fa fa-clock"></i>{event.timezone}</li>
//                                     )}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {showSharePopup && (
//                 <SharePopup
//                     link={shareableLink}
//                     onClose={() => setShowSharePopup(false)}
//                 />
//             )}
//         </div>
//     );
// };

// export default EventDetails;


// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams } from 'react-router-dom';
// import Spinner from '../components/common/Spinner';
// import Map from '../components/common/Map';
// import ContentStats from '../components/ratings/ContentStats';
// import ReviewForm from '../components/ratings/ReviewForm';
// import ReviewStats from '../components/ratings/ReviewStats';
// import ReviewList from '../components/ratings/ReviewList';
// import SharePopup from '../components/common/SharePopup';

// const EventDetails = () => {
//     const { slug } = useParams();
//     const [event, setEvent] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
//     const [showSharePopup, setShowSharePopup] = useState(false);

//     useEffect(() => {
//         const fetchEventDetails = async () => {
//             try {
//                 const response = await fetch(`/api/event/${slug}/`);
//                 const data = await response.json();
//                 console.log('Fetched event details:', data);

//                 const geometry = data.geometry;
//                 const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
//                 const match = geometry.match(pointRegex);

//                 if (match) {
//                     setCoordinates({
//                         lat: parseFloat(match[2]),
//                         lng: parseFloat(match[1]),
//                     });
//                 }

//                 setEvent(data.properties); 
//             } catch (error) {
//                 console.error('Error fetching event details:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchEventDetails();
//     }, [slug]);

//     const fetchReviews = useCallback(async () => {
//         try {
//             await fetch(`/api/reviews-list/event/${event.id}/`);
//         } catch (error) {
//             console.error('Error fetching reviews:', error);
//         }
//     }, [event]);

//     useEffect(() => {
//         if (event) {
//             fetchReviews();
//         }
//     }, [event, fetchReviews]);

//     if (loading) {
//         return <Spinner />;
//     }

//     if (!event) {
//         return <div>Event not found</div>;
//     }

//     const shareableLink = `${window.location.origin}/event/${slug}`;

//     return (
//         <div>
//             <section className="listing-header parallex-bg" style={{ backgroundImage: `url(${event.image || 'assets/images/1920x800.jpg'})` }}>
//                 <div className="dark-overlay" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}></div>
//                 <div className="container" style={{ position: 'relative', zIndex: 2 }}>
//                     <div className="listing-header-inner">
//                         <h1 style={{ color: '#fff' }}>{event.title || 'Event Title Not Available'}</h1>
//                         <p style={{ color: '#fff' }}>{event.venue || 'Venue not available'}
//                             <br />
//                             <strong>Start Date: </strong>
//                             <span className="post-date">
//                                 {event.start_date ? new Date(event.start_date).toLocaleDateString() : 'Date not available'}
//                             </span>
//                         </p>

//                         <div className="layout-three-address">
//                             <div className="view_map_style3">
//                                 <a href="#viewmap" className="js-target-scroll"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
//                             </div>
//                             <p className="style_3_address" style={{ color: '#fff' }}>{event.city_name || 'City not available'}</p>
//                         </div>

//                         <ReviewStats contentType="event" contentId={event.id} />
//                     </div>
//                     <div className="listing-meta">
//                         <div className="row">
//                             <ContentStats contentType="event" contentId={event.id} />
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section id="nav-detail">
//                 <ul className="nav-scroll">
//                     <li><a href="#home" className="js-target-scroll">Home</a></li>
//                     <li><a href="#schedule" className="js-target-scroll">Event Schedule</a></li>
//                     <li><a href="#venue" className="js-target-scroll">Venue</a></li>
//                     <li><a href="#description" className="js-target-scroll">About Event</a></li>
//                     <li><a href="#review" className="js-target-scroll">Comments</a></li>
//                     <li><a href="#viewmap" className="js-target-scroll">Map</a></li>
//                 </ul>
//             </section>

//             <section className="listing-details section-padding">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-8">
//                             <div className="detail-wp" id="schedule">
//                                 <div className="detail-heading">
//                                     <h3>Event Schedule</h3>
//                                 </div>
//                                 <div className="date-item">
//                                     <div className="event_start_m">
//                                         <div className="date-item_date">Event Start Date</div>
//                                         <div className="date-item_hours">{new Date(event.start_date).toLocaleDateString()}</div>
//                                     </div>

//                                     <div className="event_start_t">
//                                         <div className="date-item_date">Opening Time</div>
//                                         <div className="date-item_hours">{event.opening_time || 'Not available'}</div>
//                                     </div>
//                                 </div>
//                                 <div className="date-item">
//                                     <div className="event_start_m">
//                                         <div className="date-item_date">Event End Date</div>
//                                         <div className="date-item_hours">{new Date(event.end_date).toLocaleDateString()}</div>
//                                     </div>

//                                     <div className="event_start_t">
//                                         <div className="date-item_date">Closing Time</div>
//                                         <div className="date-item_hours">{event.closing_time || 'Not available'}</div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="detail-wp" id="venue">
//                                 <div className="detail-heading">
//                                     <h3>Event Venue</h3>
//                                 </div>
//                                 <span className="map_icon"><i className="fa fa-map-marker"></i></span>{event.address || 'Venue not available'}
//                             </div>

//                             <div className="detail-wp map-container" id="viewmap">
//                                 <div className="detail-heading">
//                                     <h3>Map</h3>
//                                 </div>
//                                 {coordinates.lat && coordinates.lng && (
//                                     <Map
//                                         latitude={coordinates.lat}
//                                         longitude={coordinates.lng}
//                                     />
//                                 )}
//                             </div>

//                             <div className="detail-wp" id="description">
//                                 <div className="detail-heading">
//                                     <h3>About Event</h3>
//                                 </div>
//                                 <p>{event.description || 'No description available for this event.'}</p>
//                             </div>

//                             <div className="detail-wp" id="review">
//                                 <div className="detail-heading">
//                                     <h3>Write Comment</h3>
//                                 </div>
//                                 <ReviewForm
//                                     contentType="event"
//                                     contentId={event.id}
//                                     onReviewSubmitted={() => {
//                                         fetchReviews(); // Refresh the review list after submission
//                                     }}
//                                 />
//                             </div>

//                             <div className="detail-wp" id="reviews">
//                                 <ReviewList contentType="event" contentId={event.id} />
//                             </div>
//                         </div>

//                         <div className="col-md-4">
//                             <div className="listing-sidebar listing_contact_info sidebar_wrap listing_action_btn">
//                                 <ul>
//                                     <li><a href="#" onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i> Share This</a></li>
//                                     <li><a href="#review" className="js-target-scroll"><i className="fa fa-comment"></i> Write a Comment</a></li>
//                                 </ul>
//                             </div>

//                             <div className="listing-sidebar listing_contact_info">
//                                 <div className="sidebar-heading">
//                                     <h5>Contact Info</h5>
//                                 </div>
//                                 <ul>
//                                     <li><i className="fa fa-map-marker"></i>{event.address}</li>
//                                     <li><i className="fa fa-phone"></i>{event.phone || 'Phone number not available'}</li>
//                                     <li><i className="fa fa-envelope-o"></i>{event.email || 'Email not available'}</li>
//                                     <li><i className="fa fa-link"></i><a href={event.website_url} target="_blank" rel="noopener noreferrer">{event.website_url || 'Website not available'}</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             {showSharePopup && (
//                 <SharePopup
//                     link={shareableLink}
//                     onClose={() => setShowSharePopup(false)}
//                 />
//             )}
//         </div>
//     );
// };

// export default EventDetails;