import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import Listings from './pages/Listings';
import Deals from './pages/Deals';
import Events from './pages/Events';
// import Pricing from './pages/Pricing';
import HowItWorks from './pages/HowItWorks';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import TermsOfService from './pages/TermsOfService';

import TotalListings from './pages/TotalListings';
import NotFound from './pages/NotFound';
import { UserProvider, useUser } from './context/UserContext';
import Spinner from './components/common/Spinner';
import ScrollToTop from './components/common/ScrollToTop';
import BusinessDetails from './pages/BusinessDetails';
import EventDetails from './pages/EventDetails';
import DealDetails from './pages/DealDetails';


const Main = () => {
  const location = useLocation();
  const showNavbar = !location.pathname.startsWith('/dashboard');
  const showFooter = !location.pathname.startsWith('/dashboard');
  const { isLoading } = useUser();

  return (
    <>
     
    
    
      {isLoading && <Spinner fullScreen />}
      {showNavbar && <Navbar />}
      <ScrollToTop />
      
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/all-listings" element={<Listings />} />
        <Route path="/deals-list" element={<Deals />} />
        <Route path="/events-list" element={<Events />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* <Route path="/select-pricing-plan" element={<Pricing />} /> */}
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/total-list/:id" element={<TotalListings/>}/>
        <Route path="/deal/:slug" element={<DealDetails />} />
        <Route path="/business/:slug" element={<BusinessDetails />} />
        <Route path="/event/:slug" element={<EventDetails />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
     
      {showFooter && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <UserProvider>
    
      <Router>
   
        <Main />
      </Router>
    </UserProvider>
  );
};

export default App;

