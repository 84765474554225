import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Search = ({ categories = [], onSearch, redirectOnSearch }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();

  const determineListType = async (keyword, category) => {
    try {
        const params = new URLSearchParams({ keyword, category });
        const response = await fetch(`/api/determine-list-type?${params}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // console.log('API Response:', data); // Log the API response
        return data.listType;
    } catch (error) {
        // console.error('Error determining list type:', error);
        return 'businesses'; // Default to businesses if there's an error
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (redirectOnSearch) {
      const listType = await determineListType(keyword, selectedCategory);
      const params = new URLSearchParams();
      if (keyword) params.append('keyword', keyword);
      if (selectedCategory) params.append('category', selectedCategory);

      let redirectPath;
      if (listType === 'businesses') {
        redirectPath = '/all-listings';
      } else if (listType === 'events') {
        redirectPath = '/events-list';
      } else if (listType === 'deals') {
        redirectPath = '/deals-list';
      }

      const fullPath = `${redirectPath}?${params.toString()}`;
      // console.log('Redirecting to:', fullPath);

      navigate(fullPath);
    } else {
      onSearch({ category: selectedCategory, keyword });
    }
  };

  return (
    <div className="search_form">
      <form onSubmit={handleSubmit}>
        <div className="form-group select">
          <select
            className="form-control"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">What are you looking for?</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search by keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <div className="form-group search_btn">
          <input type="submit" value="Search" className="btn btn-block" />
        </div>
      </form>
    </div>
  );
};

export default Search;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const Search = ({ categories = [], onSearch, redirectOnSearch }) => {
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [keyword, setKeyword] = useState('');
//   const navigate = useNavigate();

//   const determineListType = async (keyword, category) => {
//     try {
//         const params = new URLSearchParams({ keyword, category });
//         const response = await fetch(`/api/determine-list-type?${params}`);
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         console.log('API Response:', data); // Log the API response
//         return data.listType;
//     } catch (error) {
//         console.error('Error determining list type:', error);
//         return 'businesses'; // Default to businesses if there's an error
//     }
// };

// const handleSubmit = async (e) => {
//   e.preventDefault();

//   if (redirectOnSearch) {
//     const listType = await determineListType(keyword, selectedCategory);
//     const params = new URLSearchParams();
//     if (keyword) params.append('keyword', keyword);
//     if (selectedCategory) params.append('category', selectedCategory);

//     let redirectPath;
//     if (listType === 'businesses') {
//       redirectPath = '/all-listings';
//     } else if (listType === 'events') {
//       redirectPath = '/events-list';
//     } else if (listType === 'deals') {
//       redirectPath = '/deals-list';
//     }

//     const fullPath = `${redirectPath}?${params.toString()}`;
//     console.log('Redirecting to:', fullPath);

//     navigate(fullPath);
//   } else {
//     onSearch({ category: selectedCategory, keyword });
//   }
// };

//   return (
//     <div className="search_form">
//       <form onSubmit={handleSubmit}>
//         <div className="form-group select">
//           <select
//             className="form-control"
//             value={selectedCategory}
//             onChange={(e) => setSelectedCategory(e.target.value)}
//           >
//             <option value="">What are you looking for?</option>
//             {categories.map((category) => (
//               <option key={category.id} value={category.name}>
//                 {category.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="form-group">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Search by keyword"
//             value={keyword}
//             onChange={(e) => setKeyword(e.target.value)}
//           />
//         </div>
//         <div className="form-group search_btn">
//           <input type="submit" value="Search" className="btn btn-block" />
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Search;
