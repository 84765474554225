// trying new frontend controlled pagination
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Search from '../common/Search';
import Filters from '../layout/Filters';
import { useUser } from '../../context/UserContext';
import BusinessListingCard from './BusinessListingCard';
import Spinner from '../common/Spinner';
import LoadMore from '../common/LoadMore';

const BusinessList = () => {
  const [allBusinesses, setAllBusinesses] = useState([]);
  const [visibleBusinesses, setVisibleBusinesses] = useState([]);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ratingFilter, setRatingFilter] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userLocation, setUserLocation] = useState(null);
  const { categories, cities } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 12;
  const [visibleCount, setVisibleCount] = useState(itemsPerPage);

  const keyword = searchParams.get('keyword') || '';
  const category = searchParams.get('category') || '';

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
        },
        (error) => {
          // console.error("Error getting user location:", error);
          setUserLocation('144.9631 -37.8136');
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
      setUserLocation('144.9631 -37.8136');
    }
  }, []);

  useEffect(() => {
    const fetchBusinesses = async () => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        if (category) params.append('category', category);
        if (keyword) params.append('keyword', keyword);
        if (userLocation) params.append('location', userLocation);
        if (ratingFilter.length > 0) {
          const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
          params.append('rating', minRating);
        }

        const response = await fetch(`/api/business_list/?${params.toString()}`);
        const data = await response.json();

        if (data.features && Array.isArray(data.features.features)) {
          const businessesData = data.features.features.map(feature => ({
            ...feature.properties,
            location: feature.geometry,
            business_hours: Array.isArray(feature.properties.business_hours)
              ? feature.properties.business_hours
              : [],
          }));

          setAllBusinesses(businessesData);
          setVisibleBusinesses(businessesData.slice(0, itemsPerPage));
          setHasMore(businessesData.length > itemsPerPage);
        } else {
          // console.error('Unexpected API response:', data);
          setAllBusinesses([]);
          setVisibleBusinesses([]);
          setHasMore(false);
        }
      } catch (error) {
        // console.error('Error fetching businesses:', error);
        setAllBusinesses([]);
        setVisibleBusinesses([]);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBusinesses();
  }, [searchParams, category, keyword, userLocation, ratingFilter]);

  const handleLoadMore = () => {
    const newVisibleCount = visibleCount + itemsPerPage;
    setVisibleBusinesses(allBusinesses.slice(0, newVisibleCount));
    setVisibleCount(newVisibleCount);
    setHasMore(newVisibleCount < allBusinesses.length);
  };

  const handleSearch = ({ category, keyword }) => {
    setSearchParams({ category, keyword });
    setVisibleCount(itemsPerPage);
  };

  useEffect(() => {
   
    const filteredBusinesses = allBusinesses.filter(business => {
      let matchesFilter = true;

      if (filter) {
        const category = categories.find(cat => cat.name.toLowerCase() === filter.toLowerCase());
        matchesFilter = matchesFilter && business.category === category?.id;
      }

      if (cityFilter) {
        const city = cities.find(c => c.name.toLowerCase() === cityFilter.toLowerCase());
        matchesFilter = matchesFilter && business.city === city?.id;
      }

      if (ratingFilter.length > 0) {
        const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
        // console.log(`Checking if business meets rating: ${business.title} with average_rating: ${business.average_rating}`);
        matchesFilter = matchesFilter && business.average_rating >= minRating;
      }

      if (category) {
        const searchCategory = categories.find(cat => cat.name.toLowerCase() === category.toLowerCase());
        matchesFilter = matchesFilter && business.category === searchCategory?.id;
      }

      if (keyword) {
        const lowerKeyword = keyword.toLowerCase();
        matchesFilter = matchesFilter && (
          (business.title && business.title.toLowerCase().includes(lowerKeyword)) ||
          (business.description && business.description.toLowerCase().includes(lowerKeyword)) ||
          (business.tagline && business.tagline.toLowerCase().includes(lowerKeyword))
        );
      }

      return matchesFilter;
    });

    const sortedBusinesses = filteredBusinesses.sort((a, b) => {
      if (sort === 'distance_asc') {
        return (a.distance || Infinity) - (b.distance || Infinity);
      } else if (sort === 'distance_desc') {
        return (b.distance || -Infinity) - (a.distance || -Infinity);
      } else if (sort === 'asc') {
        return a.title.localeCompare(b.title);
      } else if (sort === 'desc') {
        return b.title.localeCompare(a.title);
      }
      return 0;
    });

    setVisibleBusinesses(sortedBusinesses.slice(0, visibleCount));
    setHasMore(visibleCount < sortedBusinesses.length);
  }, [allBusinesses, filter, cityFilter, ratingFilter, sort, keyword, category, visibleCount, categories, cities]);

  const handleFilterChange = (e) => {
    const [type, value] = e.target.value.split(':');
    switch (type) {
      case 'category':
        setFilter(value);
        break;
      case 'city':
        setCityFilter(value);
        break;
      case 'rating':
        setRatingFilter(prevRating => {
          if (prevRating.includes(value)) {
            return prevRating.filter(r => r !== value);
          } else {
            return [...prevRating, value];
          }
        });
        break;
      default:
        setSort(e.target.value);
    }
    setVisibleCount(itemsPerPage);
  };

  const handleClearFilters = () => {
    setFilter('');
    setCityFilter('');
    setRatingFilter([]);
    setSort('');
    setVisibleCount(itemsPerPage);
  };

  const isSortedByDistance = sort === 'distance_asc' || sort === 'distance_desc';

  return (
    <div data-new-title="Business Listings | Your Site Title">
      <section id="banner" className="parallex-bg section-padding">
        <div className="container">
          <div className="white-text text-center div_zindex">
            <h1>Business Listings</h1>
            <Search categories={categories} onSearch={handleSearch} />
          </div>
        </div>
        <div className="dark-overlay"></div>
      </section>

      <section id="inner_pages">
        <div className="container content-space-1">
          <div className="row">
            <div className="col-lg-3">
              <div className="sticky-below-nav">
                <div className="navbar-expand-lg mb-5">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="navbar-toggler btn btn-white mb-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarVerticalNavMenuEg2"
                      aria-label="Toggle navigation"
                      aria-expanded="false"
                      aria-controls="navbarVerticalNavMenuEg2"
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <span className="text-dark">Filter</span>
                        <span className="navbar-toggler-default">
                          <i className="bi-list"></i>
                        </span>
                        <span className="navbar-toggler-toggled">
                          <i className="bi-x"></i>
                        </span>
                      </span>
                    </button>
                  </div>

                  <div id="navbarVerticalNavMenuEg2" className="collapse navbar-collapse show">
                    <div className="w-100">
                      <Filters
                        categories={categories}
                        cities={[...new Set(allBusinesses.map(business => business.city).filter(city => city))]}
                        onFilterChange={handleFilterChange}
                        onClearFilters={handleClearFilters}
                        selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              {keyword || category ? (
                <div className="alert alert-info text-center">
                  <p>Your search results for {keyword && `"${keyword}"`} {category && `in category "${category}"`}:</p>
                </div>
              ) : (
                <div className="alert alert-info text-center">
                  <p>Showing all listings:</p>
                </div>
              )}
              <div className="sticky-below-nav sticky-sort-dropdown">
                <div className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
                  <h5>Business Listings</h5>
                  <div className="d-flex align-items-center">
                    <div className="mb-2 mb-sm-0 me-sm-2">
                      <select
                        className="form-select form-select-sm"
                        onChange={handleFilterChange}
                        value={sort}
                      >
                        <option value="">Sort by</option>
                        <option value="asc">A-Z (asc order)</option>
                        <option value="desc">Z-A (desc order)</option>
                        <option value="distance_asc">Location (nearest to farthest)</option>
                        <option value="distance_desc">Location (farthest to nearest)</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading && visibleCount === itemsPerPage ? (
                <Spinner />
              ) : visibleBusinesses.length > 0 ? (
                <>
                  <div className="row">
                    {visibleBusinesses.map(business => (
                      <BusinessListingCard
                        key={business.id}
                        business={business}
                        isSortedByDistance={isSortedByDistance}
                      />
                    ))}
                  </div>
                  <LoadMore isLoading={isLoading} hasMore={hasMore} onLoadMore={handleLoadMore} />
                </>
              ) : (
                <div className="alert alert-info text-center">
                  <p>Sorry, there are no businesses to show at the moment.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BusinessList;


