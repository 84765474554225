import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import Map from '../components/common/Map';
import ContentStats from '../components/ratings/ContentStats';
import ReviewForm from '../components/ratings/ReviewForm';
import ReviewStats from '../components/ratings/ReviewStats';
import ReviewList from '../components/ratings/ReviewList';
import SharePopup from '../components/common/SharePopup';
import DealListingCard from '../components/deals/DealListingCard';
import EventCard from '../components/events/EventCard';
import LoadMore from '../components/common/LoadMore';

const BusinessDetails = () => {
    const { slug } = useParams();
    const [business, setBusiness] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [deals, setDeals] = useState([]);
    const [events, setEvents] = useState([]);

    const [visibleDeals, setVisibleDeals] = useState([]);
    const [visibleEvents, setVisibleEvents] = useState([]);
    const [hasMoreDeals, setHasMoreDeals] = useState(true);
    const [hasMoreEvents, setHasMoreEvents] = useState(true);
    const [dealsVisibleCount, setDealsVisibleCount] = useState(3);
    const [eventsVisibleCount, setEventsVisibleCount] = useState(3);

    const itemsPerPage = 6; // Number of items to show per "Load More" click

    //  12 hours Clock
    const formatTime = (time) => {
        if (!time) return 'Closed';
        const [hour, minute] = time.split(':');
        const date = new Date(0, 0, 0, hour, minute);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };
    // Business Started Date 
    const parseDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day).toLocaleDateString(); // Month is 0-indexed in Date constructor
    };

    useEffect(() => {
        const fetchBusinessDetails = async () => {
            try {
                const response = await fetch(`/api/business/${slug}/`);
                const data = await response.json();
                // console.log('Fetched business details:', data);

                const geometry = data.geometry;
                const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
                const match = geometry.match(pointRegex);

                if (match) {
                    setCoordinates({
                        lat: parseFloat(match[2]),
                        lng: parseFloat(match[1]),
                    });
                }

                setBusiness(data);
            } catch (error) {
                // console.error('Error fetching business details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinessDetails();
    }, [slug]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch(`/api/event_list/?business_id=${business.id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const data = await response.json();
                    const eventsData = data.features.map(event => ({
                        ...event.properties,
                        start_date: event.properties?.start_date || "Invalid Date",
                        end_date: event.properties?.end_date || "Invalid Date",
                        opening_time: event.properties?.opening_time || "Invalid Time",
                        closing_time: event.properties?.closing_time || "Invalid Time",
                        title: event.properties?.title || "Unnamed Event",
                        description: event.properties?.description || "No description available",
                        city_name: event.properties?.city_name || "City not available",
                        category_name: event.properties?.category_name || "Category not available",
                        image: event.properties?.image || "/assets/images/default-event.jpg",
                        timezone: event.properties?.timezone || "Time Zone not available",
                    }));

                    setEvents(eventsData);
                    setVisibleEvents(eventsData.slice(0, itemsPerPage));
                    setHasMoreEvents(eventsData.length > itemsPerPage);
                } else {
                    const text = await response.text();
                    // console.error("Received non-JSON response:", text);
                    throw new Error("Received non-JSON response");
                }
            } catch (error) {
                // console.error('Error fetching events:', error);
            }
        };

        const fetchDeals = async () => {
            try {
                const response = await fetch(`/api/deal_list/?business_id=${business.id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const data = await response.json();
                    const dealsData = data.features;

                    setDeals(dealsData);
                    setVisibleDeals(dealsData.slice(0, itemsPerPage));
                    setHasMoreDeals(dealsData.length > itemsPerPage);
                } else {
                    const text = await response.text();
                    // console.error("Received non-JSON response:", text);
                    throw new Error("Received non-JSON response");
                }
            } catch (error) {
                // console.error('Error fetching deals:', error);
            }
        };

        if (business) {
            fetchEvents();
            fetchDeals();
        }
    }, [business]);

    const fetchReviews = useCallback(async () => {
        try {
            await fetch(`/api/reviews-list/business/${business.id}/`);
        } catch (error) {
            // console.error('Error fetching reviews:', error);
        }
    }, [business]);

    useEffect(() => {
        if (business) {
            fetchReviews();
        }
    }, [business, fetchReviews]);

    const handleLoadMoreDeals = () => {
        const newDealsVisibleCount = dealsVisibleCount + itemsPerPage;
        setVisibleDeals(deals.slice(0, newDealsVisibleCount));
        setDealsVisibleCount(newDealsVisibleCount);
        setHasMoreDeals(newDealsVisibleCount < deals.length);
    };

    const handleLoadMoreEvents = () => {
        const newEventsVisibleCount = eventsVisibleCount + itemsPerPage;
        setVisibleEvents(events.slice(0, newEventsVisibleCount));
        setEventsVisibleCount(newEventsVisibleCount);
        setHasMoreEvents(newEventsVisibleCount < events.length);
    };

    if (loading) {
        return <Spinner />;
    }

    if (!business) {
        return <div>Business not found</div>;
    }

    const shareableLink = `${window.location.origin}/business/${slug}`;

    return (
        <div>
            <section className="listing-header parallex-bg" style={{ backgroundImage: `url(${business.properties?.cover_photo || 'assets/images/1920x800.jpg'})` }}>
                <div className="dark-overlay" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}></div>
                <div className="container" style={{ position: 'relative', zIndex: 2 }}>
                    <div className="listing-header-inner">
                        <h1 style={{ color: '#fff' }}>{business.properties?.title || 'Business Title Not Available'} <span className="verified"><i className="fa fa-check-circle"></i></span></h1>
                        <p style={{ color: '#fff' }}>{business.properties?.address || 'Address not available'}
                        <br />
                        <strong>Since: </strong>
                            <span className="post-date">
                                {business.properties?.business_started
                                    ? parseDate(business.properties?.business_started)
                                    : 'Date not available'}
                            </span>
                        </p>

                        <div className="layout-three-address">
                            <div className="view_map_style3">
                                <a href="#viewmap" className="js-target-scroll"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
                            </div>
                            <p className="style_3_address" style={{ color: '#fff' }}>{business.properties?.city_name}</p>
                        </div>

                        <ReviewStats contentType="business" contentId={business.id} />
                    </div>
                    <div className="listing-meta">
                        <div className="row">
                            <ContentStats contentType="business"  contentId={business.id} />
                        </div>
                    </div>
                </div>
            </section>

            <section id="nav-detail">
                <ul className="nav-scroll">
                    <li><a href="#home" className="js-target-scroll">Home</a></li>
                    <li><a href="#description" className="js-target-scroll">Description</a></li>
                    <li><a href="#feature" className="js-target-scroll">Amenities</a></li>
                    <li><a href="#events" className="js-target-scroll">Event</a></li>
                    <li><a href="#deals" className="js-target-scroll">Deals</a></li>
                    <li><a href="#viewmap" className="js-target-scroll">Map</a></li>
                    <li><a href="#review" className="js-target-scroll">Reviews</a></li>
                </ul>
            </section>

            <section className="listing-details section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="detail-wp" id="description">
                                <div className="detail-heading">
                                    <h3>Listing Description</h3>
                                </div>
                                <p>{business.properties.description}</p>
                            </div>

                            <div className="detail-wp" id="feature">
                                <div className="detail-heading">
                                    <h3>Amenities</h3>
                                </div>
                                <div className="features-li">
                                    <ul>
                                        {business.properties.amenities.map((amenity, index) => (
                                            <li key={index}>{amenity.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="detail-wp" id="deals">
                                <div className="detail-heading">
                                    <h3>Deals</h3>
                                </div>
                                <div className="row">
                                    {visibleDeals.length > 0 ? visibleDeals.map(deal => (
                                        <DealListingCard key={deal.id} deal={deal} />
                                    )) : <p>No deals available for this business.</p>}
                                </div>
                                <LoadMore isLoading={false} hasMore={hasMoreDeals} onLoadMore={handleLoadMoreDeals} />
                            </div>

                            <div className="detail-wp" id="events">
                                <div className="detail-heading">
                                    <h3>Events</h3>
                                </div>
                                <div className="row">
                                    {visibleEvents.length > 0 ? visibleEvents.map(event => (
                                        <EventCard key={event.id} event={event} />
                                    )) : <p>No events available for this business.</p>}
                                </div>
                                <LoadMore isLoading={false} hasMore={hasMoreEvents} onLoadMore={handleLoadMoreEvents} />
                            </div>

                            <div className="detail-wp map-container" id="viewmap">
                                <div className="detail-heading">
                                    <h3>Map</h3>
                                </div>
                                {coordinates.lat && coordinates.lng && (
                                    <Map
                                        latitude={coordinates.lat}
                                        longitude={coordinates.lng}
                                    />
                                )}
                            </div>

                            <div className="detail-wp" id="review">
                                <div className="detail-heading">
                                    <h3>Write Review</h3>
                                </div>
                                <ReviewForm
                                    contentType="business"
                                    contentId={business.id}
                                    onReviewSubmitted={() => {
                                        fetchReviews(); // Refresh the review list after submission
                                    }}
                                />
                            </div>

                            <div className="detail-wp" id="reviews" >
                                <ReviewList contentType="business" contentId={business.id} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="listing-sidebar listing_contact_info sidebar_wrap listing_action_btn">
                                <ul>
                                    <li><a href="#" onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i> Share This</a></li>
                                    <li><a href="#review" className="js-target-scroll"><i className="fa fa-star"></i> Write a Review</a></li>
                                </ul>
                            </div>

                            <div className="listing-sidebar listing_contact_info">
                                <div className="sidebar-heading">
                                    <h5>CONTACT INFO</h5>
                                </div>
                                <ul>
                                    <li><i className="fa fa-map-marker"></i>{business.properties.address}</li>
                                    <li><i className="fa fa-phone"></i>{business.properties.phone_number}</li>
                                    <li><i className="fa fa-envelope-o"></i>{business.properties.email}</li>
                                    <li><i className="fa fa-link"></i><a href={business.properties.website_url} target="_blank" rel="noopener noreferrer">{business.properties.website_url}</a></li>
                                </ul>
                            </div>

                            <div className="listing-sidebar listing_openig-hours">
                                <div className="sidebar-heading">
                                    <h5>Opening Hours</h5>
                                </div>
                                <ul>
                                    {business.properties.business_hours.map((hours, index) => (
                                        <li key={index}>
                                            <span>{hours.day_of_week} :</span> 
                                            {hours.is_closed ? 'Closed' : `${formatTime(hours.opening_time)} - ${formatTime(hours.closing_time)}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {showSharePopup && (
                <SharePopup 
                    link={shareableLink} 
                    onClose={() => setShowSharePopup(false)} 
                />
            )}
        </div>
    );
};

export default BusinessDetails;


// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams } from 'react-router-dom';
// import Spinner from '../components/common/Spinner';
// import Map from '../components/common/Map';
// import ContentStats from '../components/ratings/ContentStats';
// import ReviewForm from '../components/ratings/ReviewForm';
// import ReviewStats from '../components/ratings/ReviewStats';
// import ReviewList from '../components/ratings/ReviewList';
// import SharePopup from '../components/common/SharePopup';
// import DealListingCard from '../components/deals/DealListingCard';
// import EventCard from '../components/events/EventCard';

// const BusinessDetails = () => {
//     const { slug } = useParams();
//     const [business, setBusiness] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
//     const [showSharePopup, setShowSharePopup] = useState(false);
//     const [deals, setDeals] = useState([]);
//     const [events, setEvents] = useState([]);

//     const formatTime = (time) => {
//         if (!time) return 'Closed'; // Handle null or undefined time values
//         const [hour, minute] = time.split(':');
//         const date = new Date(0, 0, 0, hour, minute);
//         return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//     };

//     useEffect(() => {
//         const fetchBusinessDetails = async () => {
//             try {
//                 const response = await fetch(`/api/business/${slug}/`);
//                 const data = await response.json();
//                 const geometry = data.geometry;
//                 const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
//                 const match = geometry.match(pointRegex);

//                 if (match) {
//                     setCoordinates({
//                         lat: parseFloat(match[2]),
//                         lng: parseFloat(match[1]),
//                     });
//                 }

//                 setBusiness(data);
//             } catch (error) {
//                 console.error('Error fetching business details:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchBusinessDetails();
//     }, [slug]);

//     useEffect(() => {
//         const fetchEvents = async () => {
//             try {
//                 const response = await fetch(`/api/event_list/?business_id=${business.id}`);
//                 if (!response.ok) {
//                     throw new Error(`HTTP error! status: ${response.status}`);
//                 }
//                 const contentType = response.headers.get("content-type");
//                 if (contentType && contentType.indexOf("application/json") !== -1) {
//                     const data = await response.json();
//                     console.log("Fetched Events:", data);
                    
//                     // Access the events from the 'features' array in the response
//                     setEvents(data.features.map(event => ({
//                         ...event.properties,
//                         start_date: event.properties?.start_date || "Invalid Date",
//                         end_date: event.properties?.end_date || "Invalid Date",
//                         opening_time: event.properties?.opening_time || "Invalid Time",
//                         closing_time: event.properties?.closing_time || "Invalid Time",
//                         title: event.properties?.title || "Unnamed Event",
//                         description: event.properties?.description || "No description available",
//                         city_name: event.properties?.city_name || "City not available",
//                         category_name: event.properties?.category_name || "Category not available",
//                         image: event.properties?.image || "/assets/images/default-event.jpg",
//                         timezone: event.properties?.timezone || "Time Zone not available",
//                     })));
//                 } else {
//                     const text = await response.text(); // Log the response text for debugging
//                     console.error("Received non-JSON response:", text);
//                     throw new Error("Received non-JSON response");
//                 }
//             } catch (error) {
//                 console.error('Error fetching events:', error);
//             }
//         };
    
//         const fetchDeals = async () => {
//             try {
//                 const response = await fetch(`/api/deal_list/?business_id=${business.id}`);
//                 if (!response.ok) {
//                     throw new Error(`HTTP error! status: ${response.status}`);
//                 }
//                 const contentType = response.headers.get("content-type");
//                 if (contentType && contentType.indexOf("application/json") !== -1) {
//                     const data = await response.json();
//                     console.log("Fetched Deals:", data);
                    
//                     // Access the deals from the 'features' array in the response
//                     setDeals(data.features);
//                 } else {
//                     const text = await response.text(); // Log the response text for debugging
//                     console.error("Received non-JSON response:", text);
//                     throw new Error("Received non-JSON response");
//                 }
//             } catch (error) {
//                 console.error('Error fetching deals:', error);
//             }
//         };
    
//         if (business) {
//             fetchEvents();
//             fetchDeals();
//         }
//     }, [business]);

//     const fetchReviews = useCallback(async () => {
//         try {
//             await fetch(`/api/reviews-list/business/${business.id}/`);
//         } catch (error) {
//             console.error('Error fetching reviews:', error);
//         }
//     }, [business]);

//     useEffect(() => {
//         if (business) {
//             fetchReviews();
//         }
//     }, [business, fetchReviews]);

//     if (loading) {
//         return <Spinner />;
//     }

//     if (!business) {
//         return <div>Business not found</div>;
//     }

//     const shareableLink = `${window.location.origin}/business/${slug}`;

//     return (
//         <div>
//             <section className="listing-header parallex-bg" style={{ backgroundImage: `url(${business.cover_photo || 'assets/images/1920x800.jpg'})` }}>
//                 <div className="container">
//                     <div className="listing-header-inner">
//                         <h1>{business.title} <span className="verified"><i className="fa fa-check-circle"></i></span></h1>
//                         <p>{business.properties.address} <span className="post-date">{new Date(business.created_at).toLocaleDateString()}</span></p>

//                         <div className="layout-three-address">
//                             <div className="view_map_style3">
//                                 <a href="#viewmap" className="js-target-scroll"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
//                             </div>
//                             <p className="style_3_address">{business.properties.city_name}</p>
//                         </div>

//                         <ReviewStats contentType="business" contentId={business.id} />
//                     </div>
//                     <div className="listing-meta">
//                         <div className="row">
//                             <ContentStats contentType="business" contentId={business.id} />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dark-overlay"></div>
//             </section>

//             <section id="nav-detail">
//                 <ul className="nav-scroll">
//                     <li><a href="#home" className="js-target-scroll">Home</a></li>
//                     <li><a href="#description" className="js-target-scroll">Description</a></li>
//                     <li><a href="#feature" className="js-target-scroll">Amenities</a></li>
//                     <li><a href="#events" className="js-target-scroll">Event</a></li>
//                     <li><a href="#deals" className="js-target-scroll">Deals</a></li>
//                     <li><a href="#viewmap" className="js-target-scroll">Map</a></li>
//                     <li><a href="#review" className="js-target-scroll">Reviews</a></li>
//                 </ul>
//             </section>

//             <section className="listing-details section-padding">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-8">
//                             <div className="detail-wp" id="description">
//                                 <div className="detail-heading">
//                                     <h3>Listing Description</h3>
//                                 </div>
//                                 <p>{business.properties.description}</p>
//                             </div>

//                             <div className="detail-wp" id="feature">
//                                 <div className="detail-heading">
//                                     <h3>Amenities</h3>
//                                 </div>
//                                 <div className="features-li">
//                                     <ul>
//                                         {business.properties.amenities.map((amenity, index) => (
//                                             <li key={index}>{amenity.name}</li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </div>

//                             <div className="detail-wp" id="deals">
//                                 <div className="detail-heading">
//                                     <h3>Deals</h3>
//                                 </div>
//                                 <div className="row">
//                                     {deals.length > 0 ? deals.map(deal => (
//                                         <DealListingCard key={deal.id} deal={deal} />
//                                     )) : <p>No deals available for this business.</p>}
//                                 </div>
//                             </div>

//                             <div className="detail-wp" id="events">
//                                 <div className="detail-heading">
//                                     <h3>Events</h3>
//                                 </div>
//                                 <div className="row">
//                                     {events.length > 0 ? events.map(event => (
//                                         <EventCard key={event.id} event={event} />
//                                     )) : <p>No events available for this business.</p>}
//                                 </div>
//                             </div>

//                             <div className="detail-wp map-container" id="viewmap">
//                                 <div className="detail-heading">
//                                     <h3>Map</h3>
//                                 </div>
//                                 {coordinates.lat && coordinates.lng && (
//                                     <Map
//                                         latitude={coordinates.lat}
//                                         longitude={coordinates.lng}
//                                     />
//                                 )}
//                             </div>

//                             <div className="detail-wp" id="review">
//                                 <div className="detail-heading">
//                                     <h3>Write Review</h3>
//                                 </div>
//                                 <ReviewForm
//                                     contentType="business"
//                                     contentId={business.id}
//                                     onReviewSubmitted={() => {
//                                         fetchReviews(); // Refresh the review list after submission
//                                     }}
//                                 />
//                             </div>

//                             <div className="detail-wp" id="reviews">
//                                 <ReviewList contentType="business" contentId={business.id} />
//                             </div>
//                         </div>

//                         <div className="col-md-4">
//                             <div className="listing-sidebar listing_contact_info sidebar_wrap listing_action_btn">
//                                 <ul>
//                                     <li><a href="#" onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i> Share This</a></li>
//                                     <li><a href="#review" className="js-target-scroll"><i className="fa fa-star"></i> Write a Review</a></li>
//                                 </ul>
//                             </div>

//                             <div className="listing-sidebar listing_contact_info">
//                                 <div className="sidebar-heading">
//                                     <h5>CONTACT INFO</h5>
//                                 </div>
//                                 <ul>
//                                     <li><i className="fa fa-map-marker"></i>{business.properties.address}</li>
//                                     <li><i className="fa fa-phone"></i>{business.properties.phone_number}</li>
//                                     <li><i className="fa fa-envelope-o"></i>{business.properties.email}</li>
//                                     <li><i className="fa fa-link"></i><a href={business.properties.website_url} target="_blank" rel="noopener noreferrer">{business.properties.website_url}</a></li>
//                                 </ul>
//                             </div>

//                             <div className="listing-sidebar listing_openig-hours">
//                                 <div className="sidebar-heading">
//                                     <h5>Opening Hours</h5>
//                                 </div>
//                                 <ul>
//                                     {business.properties.business_hours.map((hours, index) => (
//                                         <li key={index}>
//                                             <span>{hours.day_of_week} :</span> 
//                                             {hours.is_closed ? 'Closed' : `${formatTime(hours.opening_time)} - ${formatTime(hours.closing_time)}`}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             {/* Include SharePopup here */}
//             {showSharePopup && (
//                 <SharePopup 
//                     link={shareableLink} 
//                     onClose={() => setShowSharePopup(false)} 
//                 />
//             )}
//         </div>
//     );
// };

// export default BusinessDetails;


// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams } from 'react-router-dom';
// import Spinner from '../components/common/Spinner';
// import Map from '../components/common/Map';
// import ContentStats from '../components/ratings/ContentStats';
// import ReviewForm from '../components/ratings/ReviewForm';
// import ReviewStats from '../components/ratings/ReviewStats';
// import ReviewList from '../components/ratings/ReviewList';
// import SharePopup from '../components/common/SharePopup';

// const BusinessDetails = () => {
//     const { slug } = useParams();
//     const [business, setBusiness] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
//     const [showSharePopup, setShowSharePopup] = useState(false);

//     // Fetch business details
//     useEffect(() => {
//         const fetchBusinessDetails = async () => {
//             try {
//                 const response = await fetch(`/api/business/${slug}/`);
//                 const data = await response.json();
//                 const geometry = data.geometry;
//                 const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
//                 const match = geometry.match(pointRegex);

//                 if (match) {
//                     setCoordinates({
//                         lat: parseFloat(match[2]),
//                         lng: parseFloat(match[1]),
//                     });
//                 }

//                 setBusiness(data);
//             } catch (error) {
//                 console.error('Error fetching business details:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchBusinessDetails();
//     }, [slug]);

//     // Fetch reviews
//     const fetchReviews = useCallback(async () => {
//         try {
//             await fetch(`/api/reviews-list/business/${business.id}/`);
//         } catch (error) {
//             console.error('Error fetching reviews:', error);
//         }
//     }, [business]);

//     useEffect(() => {
//         if (business) {
//             fetchReviews();
//         }
//     }, [business, fetchReviews]);

//     if (loading) {
//         return <Spinner />;
//     }

//     if (!business) {
//         return <div>Business not found</div>;
//     }

//     const shareableLink = `${window.location.origin}/business/${slug}`;

//     return (
//         <div>
//             <section className="listing-header parallex-bg" style={{ backgroundImage: `url(${business.cover_photo || 'assets/images/1920x800.jpg'})` }}>
//                 <div className="container">
//                     <div className="listing-header-inner">
//                         <h1>{business.title} <span className="verified"><i className="fa fa-check-circle"></i></span></h1>
//                         <p>{business.properties.address} <span className="post-date">{new Date(business.created_at).toLocaleDateString()}</span></p>

//                         <div className="layout-three-address">
//                             <div className="view_map_style3">
//                                 <a href="#viewmap" className="js-target-scroll"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
//                             </div>
//                             <p className="style_3_address">{business.properties.city_name}</p>
//                         </div>

//                         {/* Use ReviewStats to display review count and average rating */}
//                         <ReviewStats contentType="business" contentId={business.id} />
//                     </div>
//                     <div className="listing-meta">
//                         <div className="row">
//                             <ContentStats contentType="business" contentId={business.id} />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dark-overlay"></div>
//             </section>

//             {/* Navigation Section */}
//             <section id="nav-detail">
//                 <ul className="nav-scroll">
//                     <li><a href="#home" className="js-target-scroll">Home</a></li>
//                     <li><a href="#description" className="js-target-scroll">Description</a></li>
//                     <li><a href="#feature" className="js-target-scroll">Amenities</a></li>
//                     <li><a href="#event" className="js-target-scroll">Event</a></li>
//                     <li><a href="#deals" className="js-target-scroll">Deals</a></li>
//                     <li><a href="#viewmap" className="js-target-scroll">Map</a></li>
//                     <li><a href="#review" className="js-target-scroll">Reviews</a></li>
//                 </ul>
//             </section>

//             <section className="listing-details section-padding">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-8">
//                             <div className="detail-wp" id="description">
//                                 <div className="detail-heading">
//                                     <h3>Listing Description</h3>
//                                 </div>
//                                 <p>{business.properties.description}</p>
//                             </div>

//                             <div className="detail-wp" id="feature">
//                                 <div className="detail-heading">
//                                     <h3>Amenities</h3>
//                                 </div>
//                                 <div className="features-li">
//                                     <ul>
//                                         {business.properties.amenities.map((amenity, index) => (
//                                             <li key={index}>{amenity.name}</li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </div>

//                             {/* Event Section */}
//                             <div className="detail-wp" id="event">
//                                 <div className="detail-heading">
//                                     <h3>Event</h3>
//                                 </div>
//                                 {/* Replace with actual event data */}
//                                 <p>No events available.</p>
//                             </div>

//                             {/* Deals Section */}
//                             <div className="detail-wp" id="deals">
//                                 <div className="detail-heading">
//                                     <h3>Deals</h3>
//                                 </div>
//                                 {/* Replace with actual deals data */}
//                                 <p>No deals available.</p>
//                             </div>

//                             <div className="detail-wp map-container" id="viewmap">
//                                 <div className="detail-heading">
//                                     <h3>Map</h3>
//                                 </div>
//                                 {coordinates.lat && coordinates.lng && (
//                                     <Map
//                                         latitude={coordinates.lat}
//                                         longitude={coordinates.lng}
//                                     />
//                                 )}
//                             </div>

//                             <div className="detail-wp" id="review">
//                                 <div className="detail-heading">
//                                     <h3>Write Review</h3>
//                                 </div>
//                                 <ReviewForm
//                                     contentType="business"
//                                     contentId={business.id}
//                                     onReviewSubmitted={() => {
//                                         fetchReviews(); // Refresh the review list after submission
//                                     }}
//                                 />
//                             </div>

//                             <div className="detail-wp" id="reviews">
//                                 <ReviewList contentType="business" contentId={business.id} />
//                             </div>
//                         </div>

//                         <div className="col-md-4">
//                             <div className="listing-sidebar listing_contact_info sidebar_wrap listing_action_btn">
//                                 <ul>
//                                     <li><a href="#" onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i> Share This</a></li>
//                                     <li><a href="#review" className="js-target-scroll"><i className="fa fa-star"></i> Write a Review</a></li>
//                                 </ul>
//                             </div>

//                             <div className="listing-sidebar listing_contact_info">
//                                 <div className="sidebar-heading">
//                                     <h5>CONTACT INFO</h5>
//                                 </div>
//                                 <ul>
//                                     <li><i className="fa fa-map-marker"></i>{business.properties.address}</li>
//                                     <li><i className="fa fa-phone"></i>{business.properties.phone_number}</li>
//                                     <li><i className="fa fa-envelope-o"></i>{business.properties.email}</li>
//                                     <li><i className="fa fa-link"></i><a href={business.properties.website_url} target="_blank" rel="noopener noreferrer">{business.properties.website_url}</a></li>
//                                 </ul>
//                             </div>

//                             <div className="listing-sidebar listing_openig-hours">
//                                 <div className="sidebar-heading">
//                                     <h5>Opening Hours</h5>
//                                 </div>
//                                 <ul>
//                                     {business.properties.business_hours.map((hours, index) => (
//                                         <li key={index}>
//                                             <span>{hours.day_of_week} :</span> {hours.is_closed ? 'Closed' : `${hours.opening_time} - ${hours.closing_time}`}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             {/* Include SharePopup here */}
//             {showSharePopup && <SharePopup link={shareableLink} onClose={() => setShowSharePopup(false)} />}
//         </div>
//     );
// };

// export default BusinessDetails;

